"use client";

import { use, useEffect, useState } from "react";
import ArtistCarousel from "./components/Carousels/ArtistCarousel";
import MusicCarousel from "./components/Carousels/MusicCarousel";
import Stats from "./dashboard/Stats";
import axios from "axios";

interface LoggedInProps {
    session: any;
  }

const LoggedIn: React.FC<LoggedInProps> = ({ session }) => {
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      async function fetchName() {
        try {
          const response = await axios.post("/api/users/get/username");
    
          setUsername(response.data.data.username);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    
      fetchName();
    }, []); // Abhängigkeit hinzugefügt
    

  return (
    <div> 
        <div className=" text-white px-4 max-w-[1400px] m-auto pb-40">
           {loading ?null :
            <div className="flex">
                <p className="text-company-green sm:text-3xl text-xl uppercase">{username}</p>
                <p className="text-white sm:text-3xl text-xl uppercase">, Welcome back!</p>
            </div>}
            <div className="mt-8">
                <Stats/>
            </div>
            <div className="mt-12">
                <MusicCarousel category="top100"/>
            </div>
        </div>
    </div>
   
  )
}

export default LoggedIn;