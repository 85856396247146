"use client";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Link from "next/link";
import CarouselMusicCard from "./CarouselCard";
import { useEffect, useState } from "react";
import axios from "axios";

interface Track {
  track_id: string;
  title: string;
  artist: string;
  picture: string;
  likes: number;
  plays: number;
  sales: number;
  status: string;
  created: string;
}

interface MusicCarouselProps {
  category: string; // Dynamische Kategorie, z. B. "top100", "newReleases" etc.
}

export default function MusicCarousel({ category }: MusicCarouselProps) {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Dynamischer Text für die Kategorie
  const [categoryText, setCategoryText] = useState("");

  useEffect(() => {
    if (category === "top100") {
      setCategoryText("Top 100");
    } else {
      setCategoryText(category.replace(/([a-z])([A-Z])/g, "$1 $2"));
    }

    async function fetchData() {
      try {
        const response = await axios.post(`/api/tracks/category`,{category});
        const data = response.data;
        setTracks(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [category]);

  return (
    <div className="relative">
      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="bg-black rounded-xl p-4 flex items-center gap-4 animate-pulse"
            >
              <div className="h-20 w-20 bg-gray-700 rounded-md"></div>
              <div className="flex flex-col flex-grow">
                <div className="bg-gray-700 h-6 w-3/4 rounded-md mb-2"></div>
                <div className="bg-gray-600 h-4 w-1/2 rounded-md mb-2"></div>
                <div className="bg-gray-600 h-4 w-1/4 rounded-md"></div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="group max-w-full bg-company-gray p-4 rounded-xl">
          <div className="flex w-full justify-between my-auto items-center mt-2 mb-2">
            <p className="text-3xl mb-4 text-company-green ml-4 capitalize">
              {categoryText}
            </p>
            <Link href={`/trending/${category}`}>
              <p className="mb-4 text-white text-opacity-60 hover:underline mr-4 my-auto">
                Show all
              </p>
            </Link>
          </div>
          <Carousel>
            <CarouselContent>
              {tracks.map((track) => (
                <CarouselItem
                  key={track.track_id}
                  className="sm:basis-1/2 md:basis-1/2 lg:basis-1/3 xl:basis-1/5 m-auto justify-center"
                >
                  <div>
                    <Link href={`/track/${track.track_id}`}>
                      <CarouselMusicCard
                        track={{
                          track_id: track.track_id,
                          title: track.title,
                          artist: track.artist,
                          picture: track.picture,
                        }}
                      />
                    </Link>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <div className="absolute top-1/2 left-6 flex items-center justify-center text-company-green opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <CarouselPrevious className="relative left-0 translate-x-0 hover:translate-x-0 border-none" />
            </div>
            <div className="absolute top-1/2 right-2 flex items-center justify-center text-company-green opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <CarouselNext className="relative right-0 translate-x-0 hover:translate-x-0 border-none" />
            </div>

          </Carousel>
        </div>
      )}
    </div>
  );
}
