import { Card, CardBody, CardFooter } from "@nextui-org/card";
import Image from "next/image";
import { BsFileEarmarkMusicFill } from "react-icons/bs";

// Interface zur Definition der Track-Informationen
interface TrackInfo {
    track_id: string; // ID des Tracks
    title: string; // Titel des Tracks
    artist: string; // Interpret des Tracks
    picture: string; // URL zum Cover-Bild
}

// MusicCard-Komponente mit Props
interface MusicCardProps {
    track: TrackInfo;
}

export default function CarouselMusicCard({ track }: MusicCardProps) {
    return (
        <Card
            radius="lg"
            className="border-none bg-black p-4 rounded-2xl w-60 h-80  hover:bg-opacity-80 group"
        >
            <CardBody className="group flex justify-center items-center">
                {track.picture ? (
                    <Image
                        src={track.picture}
                        width={176}
                        height={176}
                        alt={`Cover of ${track.title}`}
                        className="rounded-md shadow-2xl"
                    />
                ) : (
                    <div className="flex items-center justify-center w-44 h-44 bg-company-gray rounded-md shadow-2xl">
                        <BsFileEarmarkMusicFill className="text-5xl text-white text-opacity-30" />
                    </div>
                )}
            </CardBody>

            <CardFooter className="grid-cols-1 grid mt-2 h-16">
                <p className="text-sm opacity-70 text-start truncate">{track.title}</p>
                <p className="text-xs opacity-50 text-start truncate">{track.artist}</p>
            </CardFooter>
        </Card>
    );
}
