"use client";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoMdHeart } from "react-icons/io";
import { GrMoney } from "react-icons/gr";
import { FaPlay } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress } from "@nextui-org/progress";



const formatNumber = (value: number): string => {
  if (value >= 1000000) return (value / 1000000).toFixed(1) + "M";
  if (value >= 1000) return (value / 1000).toFixed(1) + "k";
  return value.toString();
};

const Stats: React.FC= () => {
  const [plays, setPlays] = useState(0);
  const [follower, setFollower] = useState(0);
  const [likes, setLikes] = useState(0);
  const [sales, setSales] = useState(0);
  const [loading, setLoading] = useState(true);

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    async function fetchStats() {
      await sleep(1000);
      try {
        const response = await axios.post("/api/profiles/stats");
        const userData = response.data;
  
        setPlays(userData.data.plays);
        setFollower(userData.data.follower);
        setLikes(userData.data.likes);
        setSales(userData.data.sales);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    }
  
    fetchStats();
  }, []);
  

  const stats = [
    { label: "Plays", value: plays, icon: <FaPlay className="text-base md:text-xl text-company-green" /> },
    { label: "Likes", value: likes, icon: <IoMdHeart className="text-base md:text-xl text-company-green" /> },
    { label: "Follower", value: follower, icon: <BsFillPeopleFill className="text-base md:text-xl text-company-green" /> },
    { label: "Sales", value: sales, icon: <GrMoney className="text-base md:text-xl text-company-green" /> },
  ];

  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      {stats.map((stat, index) => (
        <div key={index} className="bg-company-gray text-center rounded-xl p-4 md:p-6 lg:p-6">
          <p className="text-lg md:text-xl">{stat.label}</p>
          <div className="mt-2 flex items-center justify-center gap-2">
            {loading ? (
              <CircularProgress
                isIndeterminate
                classNames={{
                  svg: "w-6 h-6 md:w-8 md:h-8 drop-shadow-md",
                  indicator: 'stroke-company-green',
                  track: "stroke-company-green/10",
                }}
                aria-label="Loading"
              />
            ) : (
              <>
                {stat.icon}
                <p className="text-xl md:text-2xl text-company-green">{formatNumber(stat.value)}</p>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stats;
