"use client";

import { Button } from '@/components/ui/button';
import { AiFillFire } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { MdExpandMore } from 'react-icons/md';

export default function LoggedOut() {


    const handleScroll = () => {
      window.scrollTo({
        top: window.innerHeight - 96,
        behavior: "smooth"
      });
    };
  

  return (
  <div> 
    <div className="flex items-center justify-center -mt-24">
    <div className="h-screen w-[1400px] relative">
      <div className='justify-end flex'>
        <img src="startingpage.png" alt="hero-bg" className="h-screen object-cover justify-end" />
      </div>

      <div className='grid grid-cols-2 content-center h-screen absolute top-0 left-0'>
        <div className="flex flex-col text-justify">
          <p className="text-company-green sm:text-2xl text-xl uppercase">Welcome to coyned!</p>
          <p className="text-white sm:text-7xl text-5xl ">THE WORLDS</p>
          <p className="text-white sm:text-7xl text-5xl ">FIRST MUSIC</p>
          <p className="text-company-green sm:text-7xl text-5xl">EXCHANGE </p>
          <p className="text-white text-md opacity-60 mt-8">Unleash your creativity with the perfect tracks! Explore free licensing music designed to inspire, and join a thriving community connecting DJs and producers. Start discovering endless possibilities today!</p>
          <a href='/register'><Button className="mt-8 bg-company-green w-52 h-12 rounded-full text-company-gray">Get Started</Button></a>
        </div>

        <div></div>
      </div>
      
      <div onClick={handleScroll} className='absolute inset-x-0 justify-center text-white flex bottom-24'>
        <MdExpandMore size={60} className="transition-transform transform hover:scale-125"/>
      </div>
    </div>

    

        
    </div>
   <div className="mt-1 text-white px-4 max-w-[1400px] m-auto mb-40">
               <div>
                   <div className="flex rounded-full p-1 text-xs w-64 m-auto  bg-white bg-opacity-10 outline outline-[1px] outline-company-green justify-center">Unlock Premium Music Downloads</div>
                   <div className="flex m-auto justify-center mt-6 text-5xl font-extralight uppercase">Discover Products</div>
                   <div className="flex m-auto justify-center mt-2 text-5xl font-extralight uppercase">With the Best Pricing</div>
                   <div className="flex m-auto justify-center mt-6 text-sm opacity-60 font-extralight">Choose from the best plan, ensuring a perfect match. Need more or less?</div>
                   <div className="flex m-auto justify-center text-sm opacity-60 font-extralight">Customize your subscription for a seamless fit.</div>
               </div>
   
               <div className="flex justify-center m-auto mt-20">
                   <div className="flex pl-6 pr-6 flex-col w-80 m-4 bg-company-gray rounded-3xl mt-16 transition-all ease-in-out duration-300">
                       <div className=" text-3xl mt-8 text-left">Starter</div>
                       <div className="text-xs h-6">Best for beginner DJs building their collection</div>
                       <div className="flex mt-8 text-4xl font-extralight text-company-green justify-center mx-auto">2,99€ <p className="text-white text-xs text-opacity-50 ml-2 m-auto">/ per month</p></div>          
                       <Button className="w-full m-auto mt-8 bg-company-gray outline outline-[1px] text-sm p-2 rounded-xl hover:text-company-green hover:outline-company-green transition-all ease-in-out duration-300">Choose plan</Button>
                       <div className="h-[1px] bg-white my-8 bg-opacity-20"></div>
                       <div className="">What you will get</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">10</p> coins per month</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 mb-8"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p>No discount on additional coins</div>
   
                   </div>
   
                   <div className="flex pl-6 pr-6 flex-col w-80 m-4 bg-company-gray rounded-3xl mt-16 transition-all ease-in-out duration-300">
                       <div className=" text-3xl mt-8 text-left">Premium</div>
                       <div className="text-xs h-6">Ideal for regular DJs needing fresh tracks.</div>
                       <div className="flex mt-8 text-4xl font-extralight text-company-green justify-center mx-auto">6,99€ <p className="text-white text-xs text-opacity-50 ml-2 m-auto">/ per month</p></div>
                       <Button className="w-full m-auto mt-8 bg-company-gray outline outline-[1px] text-sm p-2 rounded-xl hover:text-company-green hover:outline-company-green transition-all ease-in-out duration-300">Choose plan</Button>
                       <div className="h-[1px] bg-white my-8 bg-opacity-20"></div>
                       <div className="">What you will get</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">25</p> coins per month</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 mb-8 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">5%</p> discount on additional coins</div>
   
                   </div>
   
                   <div className="flex pl-6 pr-6 flex-col w-80 m-4 bg-company-gray rounded-3xl outline outline-[2px] outline-company-green transition-all ease-in-out duration-300">
                       <div className="flex ml-auto justify-end mt-6 text-xs p-2 rounded-full bg-company-gray"><p className="text-company-green m-auto pr-1"><AiFillFire /> </p>Most popular</div>
                       <div className=" text-3xl text-left">Ultimate</div>
                       <div className="text-xs h-6">Suited for experienced DJs.</div>
                       <div className="flex mt-8 text-4xl font-extralight text-company-green justify-center mx-auto">13,49€ <p className="text-white text-xs text-opacity-50 ml-2 m-auto">/ per month</p></div>
                       
                       <Button className="w-full m-auto mt-14 bg-company-green outline outline-[1px] outline-company-gray text-company-gray text-sm p-2 rounded-xl transition-all ease-in-out duration-300">Choose plan</Button>
                       <div className="h-[1px] bg-white my-8 bg-opacity-20"></div>
                       <div className="">What you will get</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">50</p> coins per month</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 mb-8 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">10%</p> discount on additional coins</div>
   
                   </div>
   
                   <div className="flex pl-6 pr-6 flex-col w-80 m-4 bg-company-gray rounded-3xl mt-16 transition-all ease-in-out duration-300">
                       <div className=" text-3xl mt-8 text-left">Elite</div>
                       <div className="text-xs h-6">Best for Pros with high music demands.</div>
                       <div className="flex mt-8 text-4xl font-extralight text-company-green justify-center mx-auto">24,99€ <p className="text-white text-xs text-opacity-50 ml-2 m-auto">/ per month</p></div>
                       <Button className="w-full m-auto mt-8 bg-company-gray outline outline-[1px] text-sm p-2 rounded-xl hover:text-company-green hover:outline-company-green transition-all ease-in-out duration-300">Choose plan</Button>
                       <div className="h-[1px] bg-white my-8 bg-opacity-20"></div>
                       <div className="">What you will get</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">100</p> coins per month</div>
                       <div className="flex font-extralight text-sm text-white text-opacity-70 mt-2 mb-8 items-center"><p className="my-auto pr-2"><IoIosCheckmarkCircleOutline /></p> <p className="text-company-green text-lg mr-1">20%</p> discount on additional coins</div>
                   </div>
               </div>
   
               <div className="mt-16 justify-center flex">
                   <Button className="w-96 m-auto mt-8 bg-company-green text-company-gray text-xl p-2 rounded-full hover:-translate-y-1 transition-all ease-in-out duration-300">How it works <p className="ml-2"><FaArrowRight /></p> </Button>
               </div>
           </div>

    <div className='w-full max-w-[1400px] text-white'>

    </div>

  </div> 
  )
}
